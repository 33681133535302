// ==============================
// * PAGE DESIGNER: HIGHLIGHT CONTENT
//
// ? This file gathers all the custom Highlight Content CSS.
// ==============================

@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.highlight-content {
    a {
        flex-flow: row nowrap;
        text-decoration: none;
    }

    &__image {
        max-width: 100vw;
        width: 100%;

        @include media-breakpoint-up(sm) {
            img {
                max-width: 100%;
                width: 100%;
                max-height: 100vh;
            }
        }

        @include media-breakpoint-up(md) {
            max-width: 66vw;
        }
    }

    &__content {
        max-width: 100vw;
        width: 100%;
        padding: 30px 25px;

        @include media-breakpoint-up(lg) {
            max-width: 30vw;
            padding: 40px 30px 40px 50px;
        }

        &__text {
            h2,
            h3,
            h4 {
                margin-bottom: 0;
                text-transform: uppercase;

                &:nth-last-of-type(1) {
                    margin-bottom: 20px;
                }
            }

            p,
            ul {
                margin-bottom: 30px;
            }
        }

        &__link {
            .btn {
                @include media-breakpoint-up(sm) {
                    border-radius: $rounded-pill;
                    padding: 24px 22px;

                    i {
                        margin-left: 0;
                        font-size: rem(7px);
                    }

                    span {
                        @include media-breakpoint-up(md) {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
